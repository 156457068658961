import merge from 'lodash/merge';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import {
  getClearSectionState,
  getFailureState,
  getLoadingState,
  getSuccessState,
} from 'dmpconnectjsapp-base/reducers/helpers';
import { dmpconnectVirtualPrinterActionConstants } from '../constants';

const initialState = {
  [apiSections.VIRTUAL_PRINTER_SHOW_OVERLAY]: true,
  [apiSections.VIRTUAL_PRINTER_NB_FILES]: 0,
  [apiSections.VIRTUAL_PRINTER_FILES_SECTION]: {},
  [apiSections.SEND_DOCUMENT_SECTION]: {},
  ins: null,
};

const toggleOverlay = (state) => {
  const { [apiSections.VIRTUAL_PRINTER_SHOW_OVERLAY]: show } = state;

  return { [apiSections.VIRTUAL_PRINTER_SHOW_OVERLAY]: !show };
};

const getPrintedFilesCount = (state, action) => {
  const {
    data: {
      message: { i_count = null } = {},
    } = {},
  } = action;
  const {
    [apiSections.VIRTUAL_PRINTER_NB_FILES]: count,
    [apiSections.VIRTUAL_PRINTER_SHOW_OVERLAY]: show,
  } = state;
  if (i_count !== null) {
    return {
      [apiSections.VIRTUAL_PRINTER_NB_FILES]: i_count,
      [apiSections.VIRTUAL_PRINTER_SHOW_OVERLAY]: i_count > 0 && i_count !== count ? true : show,
    };
  }
  return {};
};

export function dmpconnectVirtualPrinter(state = initialState, action) {
  switch (action.type) {
    case dmpconnectVirtualPrinterActionConstants.DMPC_VIRTUAL_PRINTER_SEND_COMMAND:
      return merge({}, state, getLoadingState(action));
    case dmpconnectVirtualPrinterActionConstants.DMPC_VIRTUAL_PRINTER_COMMAND_SUCCESS:
      return merge({}, state, getSuccessState(action));
    case dmpconnectVirtualPrinterActionConstants.DMPC_VIRTUAL_PRINTER_COMMAND_FAILURE:
      return merge({}, state, getFailureState(action));
    case dmpconnectVirtualPrinterActionConstants.DMPC_VIRTUAL_PRINTER_CLEAR_SECTION:
      return getClearSectionState(action, state);
    case dmpconnectVirtualPrinterActionConstants.DMPC_VIRTUAL_PRINTER_TOGGLE_OVERLAY:
      return merge({}, state, toggleOverlay(state));
    case dmpconnectVirtualPrinterActionConstants.DMPC_VIRTUAL_PRINTER_MONITORING_RECEIVED_FILE:
      return merge({}, state, getPrintedFilesCount(state, action));
    case dmpconnectVirtualPrinterActionConstants.DMPC_VIRTUAL_PRINTER_SELECT_INS:
      return merge({}, state, { ins: action.ins });
    default:
      return state;
  }
}
